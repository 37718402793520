<template>
  <div>
    <ag-table
      :gridOptions="gridOptions"
      :rowData="subProduct.sub_product_feedback"
      @emitAddNewRecord="createRecord()"
      @emitDeleteMultipleRecords="deleteRecords($event, null , true)"/>

    <store-popup
      :sub-product-feedback="subProductFeedback"
      :sub-product-id="$route.params.subProductId"
      @created="subProductFeedhbackCreated"
      @updated="subProductFeedbackUpdated"
      ref="storePopupRef"
    />
  </div>
</template>

<script>
import StorePopup from "./StorePopup";
import {RepositoryFactory} from '../../../repositories/ecommerceRepositoryFactory'
import AgTable from '../../../../shared/shared-components/ag-grid/AgTable.vue'
import Loader from '../../../../shared/shared-components/Loader'
import AgTableBtnCell from "../../../../shared/shared-components/ag-grid/AgTableBtnCell";

const subProductRepository = RepositoryFactory.get("subProduct");
const subProductFeedbackRepository = RepositoryFactory.get("subProductFeedback");

export default {
  name: "SubProductFeedback",
  data() {
    return {
      subProduct: {
        category: {},
        subProducts: [],
        sub_product_feedback: []
      },
      gridOptions: null,
      isLoading: false,
      subProductFeedback: {
        id: '',
        user: {
          id: 1,
          full_name: '',
          first_name: '',
          last_name: '',
          image_url: ''
        },
        desc: '',
        rate: ''
      }
    }
  },
  components: {
    AgTable,
    Loader,
    StorePopup
  },
  methods: {
    createRecord() {
      this.init();
      this.$refs.storePopupRef.open();
    },
    init() {
      this.subProductFeedback = {
        id: null,
        user: {
          id: 1,
          full_name: null,
          first_name: null,
          last_name: null,
          image_url: null
        },
        user_id: 1,
        desc: null,
        rate: null
      }
    },
    editRecord(subProduct) {
      this.subProductFeedback = subProduct;
      this.$refs.storePopupRef.open();
    },

    deleteRecords(ids, index, isMultiple = false) {
      this.isLoading = true;
      if (isMultiple)
        ids = ids.join(',');
      subProductFeedbackRepository.delete(ids).then((response) => {
        if (response.code === 200) {
          if (isMultiple)
            this.getAll();
          else {
            this.subProduct.sub_product_feedback.splice(index, 1);
          }
        }
        this.isLoading = false;
      })
    },

    subProductFeedhbackCreated(subProductFeedback) {
      this.subProduct.sub_product_feedback.push(subProductFeedback)
    },

    subProductFeedbackUpdated(subProductFeedback) {
      let index = this.subProduct.sub_product_feedback.findIndex(p => p.id === subProductFeedback.id);
      this.subProduct.sub_product_feedback[index] = subProductFeedback;
      this.gridApi.setRowData(this.subProduct.sub_product_feedback)
    },
    getAll() {
      subProductRepository.get(this.$route.params.subProductId).then((response) => {
        this.subProduct = response.data;
        this.isLoading = false;
      })
    },
    createColDefs() {
      let self = this;
      return [
        {
          headerName: 'المعرف',
          field: 'id',
          filter: 'agTextColumnFilter',
          suppressSizeToFit: true,
          width: 225,
          headerCheckboxSelection: true,
          checkboxSelection: true,
        },
        {
          headerName: 'اسم المستخدم',
          field: 'user.full_name',
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'الشرح',
          field: 'desc',
        },
        {
          headerName: 'التقييم',
          field: 'rate',
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'الإجراءات',
          field: 'id',
          cellRendererFramework: AgTableBtnCell,
          cellRendererParams: {
            deleteRecord: function (id, rowIndex) {
              self.deleteRecords(id, rowIndex)
            },
            editRecord: function (subProduct) {
              self.editRecord(subProduct)
            },
            actions: ['edit', 'delete']
          },
        },
      ];
    },
  },
  created() {
    this.gridOptions = {};
    this.getAll();
    this.gridOptions.columnDefs = this.createColDefs();
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  }
}
</script>
